import React from "react";
import BreadcrumbWrapper from "./Breadcrumb.style";
import { Link, useLocation } from "react-router-dom";
import config from "../../config";
import { ArrowRightOutlined } from '@ant-design/icons';

const ApplicationBreadcrumb = (props) => {
  const location = useLocation();
  const arrLocations = location.pathname.split("/");
  const pathname = arrLocations[arrLocations.length - 1];

  const getBreadcramb = () => {
    const result = config.breadcrumb[pathname];
    if (!result || !result.length) {
      return null;
    }
    return (
      <div className="breadcrumb-details">
        {result.map((ele, i) => {
          return ele.link ? (
            <React.Fragment  key={ele.link}>
              <Link
                className="breadcrumb-item font-fm-regular"
                to={ele.link}
              >
                {ele.label}
              </Link>
              <ArrowRightOutlined className="arrow-next" />
            </React.Fragment>
          ) : (
            <p key={ele.label} className="breadcrumb-item font-fm-regular">
              {ele.label}
            </p>
          );
        })}
      </div>
    );
  };
  return (
    <BreadcrumbWrapper>
      <p className="page-title font-fm-bold">{config.pagesTitle[pathname]}</p>
      {getBreadcramb()}
    </BreadcrumbWrapper>
  );
};

export default ApplicationBreadcrumb;
