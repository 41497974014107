export const actions = {
    UPDATE_USER_INFO:"update-user-info",
    UPDATE_LOADING:"update-loading",
    LOGOUT:"logout"
}
export const setAuthInformation = (payload)  => {
    return { type: actions.UPDATE_USER_INFO, payload }
}
export const logout = (payload) => {
    return {type:actions.LOGOUT,payload}
}
export const updateLoading = (payload) =>{
    return {type:actions.UPDATE_LOADING,payload};
}