import React, { useState, useEffect } from "react";
import ServicesWrapper from "./style";
import { services } from "../../../services";
import { Table, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";


const ViewService = () => {

    const [serviceItem, setServiceItem] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const id = location.state?.id;


    const columns = [
        {
            title: "Arabic Name",
            dataIndex: "namear",
            key: "namear",
        },
        {
            title: "English Name",
            dataIndex: "nameen",
            key: "nameen",
        },
        {
            title: "keys",
            dataIndex: "keys",
            key: "keys",
        },
        {
            title: "service URL",
            dataIndex: "serviceurl",
            key: "serviceurl",
            // width: "10px"
        },
        {
            title: "QR URL",
            dataIndex: "QRURL",
            key: "QRURL",
        },
    ];

    useEffect(() => {
        if (id) {
            getServiceById(id);
        }
    }, [id])



    const getServiceById = async (userId) => {
        const service = await services.getServiceById(userId);

        const { nameen, namear, keys, serviceurl, QRURL } = service.data;

        const keysNames = keys.map((ele) => ele.name)?.join(", ")

        setServiceItem([{
            namear,
            nameen,
            serviceurl,
            QRURL,
            keys: keysNames
        }])
    };


    return (
        <ServicesWrapper>
            <Table columns={columns} rowKey={"id"} dataSource={serviceItem} pagination={false} />
            <div className="text-center">
                <Button
                    onClick={() => navigate("services")}
                    type="primary"
                    className="mx-4 button cancel bg-transparent mt-5"
                >
                    Back
                </Button>
            </div>

        </ServicesWrapper>
    )

}



export default ViewService;