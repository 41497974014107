import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, message } from "antd";
import AddAdminWrapper from "./style";
import { entites, admins } from "../../../services";
import { useRef } from "react";

const { Option } = Select;

const AddAdmin = () => {
  const location = useLocation();
  const [entitesOptions, setEntitesOptions] = useState([]);
  const navigate = useNavigate();
  const id = location.state?.id;

  const [showResetPassword, setShowResetPassword] = useState(!id);
  const [form] = Form.useForm();
  const shouldLog = useRef(true);
  useEffect(() => {
    // get user by id ;
    if (shouldLog.current) {
      shouldLog.current = false;
      entites.getAllEntites().then((response) => {
        setEntitesOptions(
          response.data.map((ele) => {
            return {
              id: ele.id,
              label: ele.nameen,
            };
          })
        );
      });

      if (id) {
        admins.getAdminById(id).then((response) => {
          form.setFieldsValue({
            id: response.data.id,
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            entityId: response.data.entityId,
            email: response.data.email,
          });
        });
      }
    }

  }, [id, form]);

  const onFinish = (values) => {
    const myObject = { ...values };
    myObject['email'] = myObject?.email?.toLowerCase()
    delete myObject.confirm;
    myObject['role'] = "2";
    if (id) {
      myObject["id"] = id;
      admins.updateAdmin(id, myObject).then((_response) => {
        message.success("user admin updated successfully");
        navigate("/admins", { replace: true });
      });
    } else {
      admins.addNewAdmin(myObject).then((_response) => {
        message.success("user admin added successfully");
        navigate("/admins", { replace: true });
      })
    }
  };

  const handleCancel = () => {
    navigate("/admins", { replace: true });
  }

  return (
    <AddAdminWrapper>
      <Form
        form={form}
        className="row"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="on"
      >
        <Form.Item
          className="col-md-3 col-xs-12"
          name="firstname"
          label="First Name"
          rules={[{ required: true, message: "this field is required" }, { pattern: /(.|\s)*\S(.|\s)*/, message: "this field is required" },]}
          validateTrigger="onBlur"
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          className="col-md-3 col-xs-12"
          name="lastname"
          label="Last Name"
          rules={[{ required: true, message: "this field is required" }, { pattern: /(.|\s)*\S(.|\s)*/, message: "this field is required" },]}
          validateTrigger="onBlur"
        >
          <Input placeholder="Last Name" />
        </Form.Item>

        <Form.Item
          className="col-md-3 col-xs-12"
          name="email"
          label="email"
          validateTrigger="onBlur"
          rules={[
            // {
            //   type: "email",
            //   message: "The input is not valid E-mail!",
            // },
            {
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Please input a valid email adderss"
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="Email" disabled={!!id} />
        </Form.Item>

        <Form.Item
          className="col-md-3 col-xs-12"
          name="entityId"
          label="Entity"
          rules={[{ required: true, message: "plese select an Entity" }]}
        >
          <Select disabled={!!id}>
            {entitesOptions.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {showResetPassword && (
          <React.Fragment>
            <Form.Item
              className="col-md-3 col-xs-12"
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  pattern: /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z])/,
                  message: "Password should be at least 8 characters and should contain: upper-case, lower-case, a number, and a symbol"
                }
              ]}
              validateTrigger='onBlur'
            >
              <Input.Password maxLength={12} autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              name="confirm"
              className="col-md-3 col-xs-12"
              label="Confirm Password"
              dependencies={["password"]}
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password maxLength={12} autoComplete="new-password" />
            </Form.Item>
          </React.Fragment>
        )}

        <Form.Item className="mt-4 text-center">

          {id && (
            <div className="mb-5">
              <Button
                onClick={() => setShowResetPassword(!showResetPassword)}
              >
                {showResetPassword
                  ? "Hide Reset Password"
                  : "Show Reset Password"}
              </Button>
            </div>
          )}
          <div className="row justify-content-center">
            <div className="d-flex justify-content-evenly col-6 align-self-center">
              <Button
                onClick={handleCancel}
                type="primary"
                className="button cancel bg-transparent"
              >
                Cancel
              </Button>
              <Button className="submit-btn" htmlType="submit">
                Submit
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </AddAdminWrapper>
  );
};

export default AddAdmin;
