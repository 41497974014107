import React, { useCallback, useState,useLayoutEffect } from "react";
import { Table, Button, Input } from "antd";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { entites } from "../../../services";
import EntityWrapper from "./style";
import { useRef } from "react";

const EntitiesList = (_props) => {
  const navigate = useNavigate();

  const [entitesList, setEntitesList] = useState([]);
  const [allEntitiesList, setAllEntitiesList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const addEntity = () => {
    navigate("add-entity", { replace: true });
  };
  const editEntity = (row) => {
    navigate("update-entity", { replace: true, state: { id: row.id } });
  };

  const deleteEntity = (row) => {
    entites.deleteEntity(row.id).then((_response) => {
      getEntitesList();
    });
  };

  const getEntitesList = useCallback(() => {
    entites.getAllEntites().then((response) => {
      const data = response.data.map((ele) => {
        return {
          id: ele.id,
          nameAr: ele.namear,
          nameEn: ele.nameen,
          code: ele.code,
          numOfServices: ele.services && ele.services.length,
        };
      });
      setAllEntitiesList(data);
      setEntitesList(data);
    });
  }, []);

  const shouldLog = useRef(true);
  useLayoutEffect(() => {
    if(shouldLog.current){
      shouldLog.current = false;
      getEntitesList();
    }
  }, [getEntitesList]);

  const columns = [
    {
      title: "Name (English)",
      dataIndex: "nameEn",
      key: "nameEn",
    },
    {
      title: "Name (Arabic)",
      dataIndex: "nameAr",
      key: "nameAr",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "# of Services",
      dataIndex: "numOfServices",
      key: "numOfServices",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => {
        return (
          <React.Fragment>
            <Button
              className="mx-2"
              type="primary"
              onClick={() => editEntity(record)}
              icon={<EditOutlined />}
            />
            <Button
              onClick={() => deleteEntity(record)}
              className="mx-2"
              type="primary"
              danger
              icon={<DeleteOutlined />}
            />
          </React.Fragment>
        );
      },
    },
  ];

  const submitFilter = () => {
    if(!searchFilter){
      return;
    }
    const filteredEntities = allEntitiesList.filter((ele) => {
      return (
        ele.nameAr?.toLowerCase().indexOf(searchFilter?.toLowerCase()) !== -1 ||
        ele.nameEn?.toLowerCase().indexOf(searchFilter?.toLowerCase()) !== -1 ||
        ele.code?.toLowerCase().indexOf(searchFilter?.toLowerCase()) !== -1
      );
    });
    setEntitesList(filteredEntities);
  };

  const resetFilter = () => {
    setSearchFilter("");
    setEntitesList(allEntitiesList);
  };

  return (
    <EntityWrapper>
      <div className="my-2 d-flex justify-content-end">
        <Button onClick={addEntity} type="primary">
          Add New Entity
        </Button>
      </div>
      <div className="my-2 d-flex filter row">
        <div className="col-3">
          <Input
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            placeholder="search ..."
          />
        </div>
        <div className="col-2 d-flex justify-content-between">
          <Button
            onClick={submitFilter}
            type="primary"
          >
            Search
          </Button>
          <Button
            onClick={resetFilter}
            className="mx-2"
            type="primary"
            danger
          >
            Reset
          </Button>
        </div>
      </div>
      <Table rowKey={"code"} columns={columns} dataSource={entitesList} />
    </EntityWrapper>
  );
};

export default EntitiesList;
