import React from 'react';
import './App.css';
import ApplicationLayout from './layout/Layout';
import { Provider } from 'react-redux';
import store from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <ApplicationLayout />
    </Provider>

  );
}

export default App;
