import React, { useState, useEffect } from "react";
import SidebarWrapper from "./Sidebar.style";

import { Layout, Menu } from "antd";
import qrCode from "../../assets/images/qr-code-scan.svg";
import { useSelector } from "react-redux";
import utils from "../../helpers/utils";

const { Sider } = Layout;

const ApplicationSidebar = (props) => {
  const userRole = useSelector((state) => state.userInfo.role);
  const isAdmin = utils.isAdmin(userRole);
  const [selectedKeys,setSelectedKeys] = useState(isAdmin ? ['1']: ['3']);

  useEffect(()=>{
    if(selectedKeys[0] === '5' && isAdmin){
      setSelectedKeys(['1'])
    } else if(selectedKeys[0] === '4' && !isAdmin){
      setSelectedKeys(['3'])
    }
  },[selectedKeys, isAdmin])


  return (
    <SidebarWrapper>
      <Sider
        // collapsedWidth={0}
        trigger={null}
        collapsible={true}
        collapsed={props.collapsed}
        width={250}
      >
        <div className="logo">
          <img src={qrCode} width="35" alt="qr code" />
          {!props.collapsed && (
            <p className="m-0 font-fm-bold">QR Code Management System</p>
          )}
        </div>

        <Menu
          className="menu-sidebar"
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
          onSelect={(ele) => {setSelectedKeys(ele.selectedKeys)}}
          // defaultSelectedKeys={["1"]}
          items={utils.getItemsSidebar(isAdmin)}
        />
      </Sider>
    </SidebarWrapper>
  );
};
export default ApplicationSidebar;
