import styled from 'styled-components';
const HeaderWrapper = styled.div`
.ant-layout-header{
    line-height:0;
    background:#036822;
    height:70px;
}
 .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    border:none;
    background:transparent;
    outline:none;
    &:hover{
        color: #1890ff;
    }
}
.user-info{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    color:white;
}
.welcome-p {
    font-size: 25px;
}
.user-role{
    font-size: 20px
}
`;

export default HeaderWrapper;