import React, { useState } from "react";
import ApplicationHeader from "../header/Header";
import ApplicationSidebar from "../sidebar/Sidebar";
import ApplicationNavbar from "../navbar/Navbar";
import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import MediaQuery from 'react-responsive';
import AppLayoutWrapper from "./style";


const PrivateLayout = () => {
  const [collapse, setCollapse] = useState(false);

  const toggle = () => {
    setCollapse(!collapse);
  };
  return (
    <React.Fragment>
      <MediaQuery maxWidth={768}>        
          <ApplicationNavbar toggle={toggle} collapsed={collapse} />
      </MediaQuery>
     
      <MediaQuery minWidth={769}>        
        <ApplicationSidebar collapsed={collapse} />
        </MediaQuery>
      
      <Layout className="site-layout">
        <ApplicationHeader collapsed={collapse} toggle={toggle} />
        <AppLayoutWrapper className="container  h-100">
          <Outlet />
        </AppLayoutWrapper>
      </Layout>
    </React.Fragment>
  );
};
export default PrivateLayout;
