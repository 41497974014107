import React, { useEffect, useState } from "react";
import AddServiceWrapper from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { services } from "../../../services";
import { Form, Input, message, Button, Select, Checkbox } from "antd";
// import JSONInput from "react-json-editor-ajrm";
// import locale from "react-json-editor-ajrm/locale/en";
import info from "../../../assets/images/info.svg";

import { useRef } from "react";
import config from "../../../config";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const { Search } = Input;

const AddService = (_props) => {
  const [keysOptions, setKeysOptions] = useState([]);
  const [authType, setAuthType] = useState("");
  const [methodType, setMethodType] = useState("GET");
  const [jsObject, setJsObject] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const id = location.state?.id;

  const shouldLog = useRef(true);

  useEffect(() => {
    // GET user by id ;
    if (id && shouldLog.current) {
      shouldLog.current = false;
      getServiceById(id);
    }
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({ methodType });
  }, []);

  const onFinish = () => {
    message.success("Submit success!");
  };

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const getServiceKeys = (url) => {
    const {
      username,
      password,
      token,
      clientID,
      clientSecret,
      authenticationType,
      method_type,
      postBody,
    } = form.getFieldsValue();

    const urlRegex = config.regex.url;

    const headersValues = {
      Authorization_type: getAuthType(authenticationType),
      Authorization_username: username,
      Authorization_password: password,
      Authorization_token: token,
      Authorization_clientid: clientID,
      Authorization_secret: clientSecret,
      method_type,
    };
    if (
      !url ||
      (methodType === "POST" && !postBody) ||
      !urlRegex.test(url.trim())
    ) {
      return;
    }

    services
      .getServiceKeys(url, headersValues, postBody && jsObject)
      .then((response) => {
        const myKeyOptions = response.data.map((ele) => (
          <Option key={ele.name} value={ele.name}>
            {ele.name}
          </Option>
        ));
        setKeysOptions(myKeyOptions);
      });
  };

  const getKeysFromState = (data) => {
    if (data.serviceurl) {
      getServiceKeys(data.serviceurl);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setKeysOptions([]);
    navigate("/services");
  };

  const getAuthType = (authenticationType) => {
    switch (authenticationType) {
      case "openID":
        return 0;
      case "basic":
        return 1;
      case "token":
        return 2;
      default:
        return -1;
    }
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.keys = values.keys.map((key) => {
        return { name: key };
      });

      const {
        username,
        password,
        token,
        clientID,
        clientSecret,
        authenticationType,
        method_type,
        postBody,
        ...restValues
      } = values;

      const headersValues = {
        Authorization_type: getAuthType(authenticationType),
        Authorization_username: username,
        Authorization_password: password,
        Authorization_token: token,
        Authorization_clientid: clientID,
        Authorization_secret: clientSecret,
        method_type,
      };

      restValues["postBody"] = postBody;

      if (location.state) {
        services
          .updateService(location.state.id, restValues, headersValues)
          .then(() => {
            navigate("/services", { replace: true });
          });
      } else {
        services.addNewService(restValues, headersValues).then(() => {
          setTimeout(() => {
            navigate("/services", { replace: true });
          }, 1000);
        });
      }
    } catch (err) {
      return;
    }
  };

  const getServiceById = async (userId) => {
    const service = await services.getServiceById(userId);

    const myEditService = {
      ...service.data,
      keys: service.data.map((ele) => ele.name),
    };
    // const keys = myEditService.keys.map((newService) => newService.name);
    // myEditService.keys.map((newService) => {
    //   keys.push(newService.name);
    // });
    // myEditService["keys"] = keys;
    getKeysFromState(service.data);
    form.setFieldsValue(myEditService);
  };

  const onChangeAuthType = (e) => {
    form.setFieldsValue({ authenticationType: e });
    setAuthType(e);
  };

  const onChangeMethodType = (e) => {
    form.setFieldsValue({ method_type: e });
    setMethodType(e);
  };
  const handleJsonBlur = (e) => {
    try {
      // const value = form.values["postBody"] || e.target.value;
      // if (e.target.value && JSON.parse(value)) {
      form.setFieldsValue({ postBody: e.target.value });
      setJsObject(JSON.parse(e.target.value));
      // }
    } catch (err) {
      console.log("Not JSON");
    }

    // if (!e.error) {
    //   form.setFieldsValue({ postBody: e.plainText });
    //   setJsObject(e.jsObject);
    // }
  };

  return (
    <AddServiceWrapper>
      <Form
        form={form}
        className="row"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          className="col-md-4 col-xs-12"
          name="nameen"
          label="Service English Name"
          rules={[
            {
              required: true,
              message: "this field is required",
            },
            {
              type: "string",
              min: 6,
              message: "Service English name must be at least 6 characters",
            },
            {
              pattern: /\w/,
              message: "This Field accepts only English characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="col-md-4 col-xs-12"
          name="namear"
          label="Service Arabic Name"
          rules={[
            { required: true, message: "this field is required" },
            {
              type: "string",
              min: 6,
              message: "Service Arabic name must be at least 6 characters",
            },
            {
              pattern: config.regex.arabicPattern,
              message: "This Field accepts only Arabic characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="col-md-4 col-xs-12"
          name="authenticationType"
          label="Authentication Type"
          rules={[{ required: true, message: "this field is required" }]}
        >
          <Select
            onChange={onChangeAuthType}
            style={{ width: "100%" }}
            placeholder="Please select authentication type"
          >
            <Option value="token">Token</Option>
            <Option value="basic">Basic Auth</Option>
            <Option value="openID">Open ID</Option>
            <Option value="noAuth">No Auth</Option>
          </Select>
        </Form.Item>

        <Form.Item
          className="col-md-4 col-xs-12"
          name="method_type"
          label="Request Type"
          rules={[{ required: true, message: "this field is required" }]}
        >
          <Select
            onChange={onChangeMethodType}
            style={{ width: "100%" }}
            placeholder="Please select authentication type"
          >
            <Option value="GET">GET</Option>
            <Option value="POST">POST</Option>
          </Select>
        </Form.Item>

        {authType === "token" && (
          <Form.Item
            className="col-md-4 col-xs-12"
            name="token"
            label="Token"
            rules={[
              {
                required: true,
                message: "this field is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        {authType === "basic" && (
          <>
            <Form.Item
              className="col-md-4 col-xs-12"
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="col-md-4 col-xs-12"
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        {authType === "openID" && (
          <>
            <Form.Item
              className="col-md-4 col-xs-12"
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="col-md-4 col-xs-12"
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="col-md-4 col-xs-12"
              name="clientID"
              label="Client ID"
              tooltip={{
                icon: <img src={info} alt="info" width={15} />,
                title:
                  "Client Id Header: Client Id Value (example x-ibm-client-id:a8f50a698e)",
              }}
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              className="col-md-4 col-xs-12"
              name="clientSecret"
              label="Client Secret"
              tooltip={{
                icon: <img src={info} alt="info" width={15} />,
                title:
                  "Client Secret Header: Client secret Value (example x-ibm-client-secret:a8f50a698e)",
              }}
              rules={[
                {
                  required: true,
                  message: "this field is required",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        {methodType === "POST" && (
          <div className="row">
            <Form.Item
              className="col-md-4 col-xs-12"
              name="postBody"
              label="Add Request Body"
              rules={[
                {
                  required: methodType === "POST",
                  message: "this field is required",
                },
              ]}
            >
              <TextArea rows={4} onChange={handleJsonBlur} />
              {/* <JSONInput
                id="a_unique_id"
                height="200px"
                width="350px"
                confirmGood={false}
                locale={locale}
                // onBlur={handleJsonBlur}
                theme="dark_vscode_tribute"
                style={{ warningBox: { display: "none" } }}
              /> */}
            </Form.Item>
          </div>
        )}

        <div className="row">
          <Form.Item
            className="col-md-4 col-xs-12"
            name="serviceurl"
            label="Service Url"
            rules={[
              { required: true, message: "this field is required" },
              { pattern: config.regex.url, message: "Please add a valid URL" },
            ]}
          >
            <Search placeholder="URL" allowClear onSearch={getServiceKeys} />
          </Form.Item>

          <Form.Item
            className="col-md-4 col-xs-12"
            name="keys"
            label="Select Key Elements"
            rules={[{ required: true, message: "this field is required" }]}
          >
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select keys"
              disabled={!keysOptions.length}
            >
              {keysOptions}
            </Select>
          </Form.Item>
        </div>

        {/* <Form.Item label=" " className="col-md-4 col-xs-12" name="offline">
          <Checkbox>Offline</Checkbox>
        </Form.Item> */}

        <Form.Item className="mt-4 text-center">
          <Button
            onClick={handleCancel}
            type="primary"
            className="mx-4 button cancel bg-transparent"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            htmlType="submit"
            className="mx-4 button"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </AddServiceWrapper>
  );
};

export default AddService;
