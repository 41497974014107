import { actions } from "../actions";
import utils from "../../helpers/utils";

const initialState = {
    isLoggedIn: utils.hasAccessToken(),
    userInfo: {
        name: sessionStorage.getItem('name') || "",
        authToken: sessionStorage.getItem('accessToken') || "",
        role: sessionStorage.getItem('role') || "",
    },
    isLoading: false
};


function rootReducer(state = initialState, action) {
    if (action.type === actions.UPDATE_USER_INFO) {
        return {
            ...state,
            isLoggedIn: true,
            userInfo: {
                name: action.payload.name,
                authToken: action.payload.token,
                role: action.payload.role
            }

        };
    }
    if (action.type === actions.LOGOUT) {
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem("role")
        sessionStorage.removeItem("name")
        return {
            ...state,
            isLoggedIn: false
        };
    }
    if (action.type === actions.UPDATE_LOADING) {
        return {
            ...state,
            isLoading: action.payload
        }
    }
    return state;
}

export default rootReducer;