import styled from 'styled-components';

const AddAdminWrapper = styled.div`
    .ant-btn{
        background: white;
        min-width: 150px;
        border: 1px solid #036822;
        height: 42px;
        border-radius: 5px;
        color: #036822;
    }

    .submit-btn{
        color:white;
        background: #036822;
    }

`;
export default AddAdminWrapper;