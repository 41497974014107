import {authInstance} from "./instance";
// import  mock from './mock.json';


export const login = async (data) => {
    return authInstance.post('/login',data);
    // return mock.authentication.login.success;
}
export const logout = async () => {
    return { status: 200, data: {} }
    // return authInstance.post('/logout');
    // return mock.authentication.logout.success;

}