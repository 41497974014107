import styled from 'styled-components';

const NavbarWrapper = styled.div`
    .logo {
        height: 80px;
        background: #650C0A;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 20px;
    }
    // @media only screen and (max-width: 768px) {
    //     .logo {
    //         font-size: 25p;
    //     }
    // }
    .ant-menu-item{
        font-size:17px;
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected{
        background:transparent;
        border-left: 4px solid #E2E6FF;
    }
    .menu-sidebar{
        background:#036822;
    }

`;

export default NavbarWrapper;