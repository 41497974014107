import React from "react";
import HeaderWrapper from "./Header.style";
import { Button, Layout, Menu, Dropdown, Space } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import ApplicationBreadcrumb from "../../components/breadcrumb/Breadcrumb";
import Logo from "../../assets/images/logo.png";
import { DownOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import config from "../../config";
import utils from "../../helpers/utils";
import { authentication } from "../../services";

const { Header } = Layout;

const ApplicationHeader = (props) => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menu = (
    <Menu
      items={[
        {
          label: <p className="m-0">Logout</p>,
          key: "0",
          onClick: () => {
            authentication.logout().then((response) =>{
              dispatch(logout());
              navigate("/login", { replace: true });
            });
          },
        },
      ]}
    />
  );
  return (
    <HeaderWrapper>
      <Header
        className="site-layout-background d-flex justify-content-between"
        style={{ padding: 0 }}
      >
        {isLoggedIn && (
          <div className="app-header-left d-flex flex-row col-6">
            <Button
              className="h-100 trigger mx-2 text-white"
              onClick={props.toggle}
            >
              {props.collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>
            <MediaQuery minWidth={config.breakdowns.DESKTOP_MIN_WIDTH}>
              <div className="app-breadcrumb">
                <ApplicationBreadcrumb />
              </div>
            </MediaQuery>
          </div>
        )}

        <div className="app-header-right d-flex flex-reverse-row justify-content-end w-100">
          {isLoggedIn && (
            <div className="user-info font-fm-bold">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a
                  href="/"
                  className="text-white text-decoration-none"
                  onClick={(e) => e.preventDefault()}
                >
                  <Space>
                    <div className="d-flex flex-column">
                      <p className="welcome-p">Welcome, {userInfo.name}</p>
                      <p className="user-role">{utils.getRoleDescription(userInfo.role)}</p>
                    </div>
                    <DownOutlined style={{ fontSize: "12px" }} />
                  </Space>
                </a>
              </Dropdown>
            </div>
          )}

          {isLoggedIn ? (
            <MediaQuery minWidth={config.breakdowns.DESKTOP_MIN_WIDTH}>
              <div className="logo">
                <img src={Logo} className="mx-4" width="150" alt="logo" />
              </div>
            </MediaQuery>
          ) : (
            <div className="logo">
              <img src={Logo} className="mx-4" width="150" alt="logo" />
            </div>
          )}
        </div>
      </Header>
    </HeaderWrapper>
  );
};
export default ApplicationHeader;
