import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Table, Button, Form, Input, Select, message } from "antd";

import AdminWrapper from "./style";
import { admins, entites } from "../../../services";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useRef } from "react";

const { Option } = Select;

const AdminsList = (_props) => {
  const navigate = useNavigate();
  const [adminsList, setAdminsList] = useState([]);
  const [allAdminsList, setAllAdminsList] = useState([]);
  const [entitesList, setEntitesList] = useState([]);
  const [form] = Form.useForm();

  const addAdmin = () => {
    navigate("add-admin", { replace: true });
  };

  const editAdmin = (row) => {
    navigate("update-admin", { replace: true, state: { id: row.id } });
  };

  const deleteAdmin = (row) => {
    admins.deleteAdmin(row.id).then((_response) => {
      message.success("user admin deleted successfully");
      const myAdmins = allAdminsList.filter((ele) => ele.id !== row.id);
      setAdminsList(myAdmins);
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (_text, record) => {
        return (
          <React.Fragment>
            <Button
              className="mx-2 edit-button"
              type="primary"
              onClick={() => editAdmin(record)}
              icon={<EditOutlined />}
            />
            <Button
              className="mx-2"
              type="primary"
              danger
              onClick={() => deleteAdmin(record)}
              icon={<DeleteOutlined />}
            />
          </React.Fragment>
        );
      },
    },
  ];

  const getAllData = useCallback(() => {
    Promise.allSettled([admins.getAllAdmins(), entites.getAllEntites()]).then(
      ([adminsResult, entitesResult]) => {
        if (adminsResult.status === "rejected") {
          message.error(adminsResult.reason);
          return;
        }
        if (entitesResult.status === "rejected") {
          message.error(entitesResult.reason);
          return;
        }

        const myEntities = entitesResult.value.data.map((ele) => {
          return {
            id: ele.id,
            label: ele.nameen,
          };
        });

        const myAdmins = adminsResult.value.data.map((ele) => {
          const entityItem = myEntities.find(
            (entityEle) => entityEle.id === ele.entityId
          );
          return {
            id: ele.id,
            email: ele.email,
            entityId: ele.entityId,
            entity: entityItem ? entityItem.label : "",
            name: ele.firstname + " " + ele.lastname,
          };
        });
        setEntitesList(myEntities);
        setAdminsList(myAdmins);
        setAllAdminsList(myAdmins);
      }
    );
  }, []);


  const shouldLog = useRef(true);
  useEffect(() => {
    if(shouldLog.current){
      shouldLog.current = false;
      getAllData();
    }
    
  }, [getAllData]);

  const onFinish = (values) => {
    let result = [...adminsList];
    if (values.search) {
      result = allAdminsList.filter((ele) => {
        return (
          ele.name?.toLowerCase().indexOf(values.search?.toLowerCase()) !== -1 ||
          ele.email?.toLowerCase().indexOf(values.search?.toLowerCase()) !== -1
        );
      });
    }
    if (values.entity) {
      if(!values.search){
        result = allAdminsList;
      }
      result = result.filter((ele) => ele.entityId === values.entity);
    }
    setAdminsList(result);
  };

  const resetFilter = () => {
    form.resetFields();
    setAdminsList(allAdminsList);
  };

  return (
    <AdminWrapper>
      <div className="my-2 d-flex justify-content-end">
        <Button onClick={addAdmin} type="primary">
          Add New Admin
        </Button>
      </div>
      <div className="my-2 d-flex filter row">
        <Form
          form={form}
          className="row d-flex"
          onFinish={onFinish}
          onFinishFailed={() => console.log("Failed")}
          autoComplete="off"
        >
          <Form.Item className="col-md-3 col-xs-12" name="search">
            <Input placeholder="Search ..." />
          </Form.Item>

          <Form.Item className="col-md-3 col-xs-12" name="entity">
            <Select placeholder="select entity">
              {entitesList.map((ele) => (
                <Option key={ele.id} value={ele.id}>
                  {ele.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item className="col-md-3 col-xs-6">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button
              onClick={resetFilter}
              type="primary"
              className="mx-1"
              danger
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Table rowKey={"id"} columns={columns} dataSource={adminsList} />
    </AdminWrapper>
  );
};

export default AdminsList;
