const config = {
    serviceApiUrl:"https://be.qr.gov.jo/gov",
    authApiUrl:"https://be.qr.gov.jo/users",
    pagesTitle: {
        "admins": "Admins Lists",
        "services": "Services Lists",
        "add-service":"Add Service",
        'update-service':"Update Service",
        "entities": "Entities Lists",
        "add-admin":"Add Admin",
        "update-admin":"Update Admin",
        "add-entity":"Add Entity",
        'update-entity':"Update Entity",
    },
    breadcrumb: {
        "admins": [
            { label: "Admins List" }
        ],
        "add-admin": [
            { label: "Admins List",link:"/admins" },
            { label: "Add New Admin"}
        ],
        "update-admin": [
            { label: "Admins List",link:"/admins" },
            { label: "Update Admin"}
        ],
        "services": [
            { label: "Services List" }
        ],
        "add-service": [
            { label: "Services List",link:"/services" },
            { label: "Add New Service"}
        ],
        "view-service": [
            { label: "Services List",link:"/services" },
            { label: "View Service"}
        ],
        "update-service": [
            { label: "Services List",link:"/services" },
            { label: "Update Service"}
        ],
        "entities": [
            { label: "Entities List" }
        ],
        "add-entity": [
            { label: "Entities List",link:"/entities" },
            { label: "Add New Entity"}
        ],
        "update-entity": [
            { label: "Entities List",link:"/entities" },
            { label: "Update Entity"}
        ],
    },
    breakdowns:{
        MOBILE_MAX_WIDTH: 768,
        DESKTOP_MIN_WIDTH:769
    },
    regex: {
        url: /^\s*https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)\s*$/,
        email: /^\s*[\w-\.]+@([\w-]+\.)+[\w-]{2,4}\s*$/,
        arabicPattern: /^[\u0621-\u064A\u0660-\u0669 \d]+$/
    }
};
export default config