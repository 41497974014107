import styled from 'styled-components';

const LoginWrapper = styled.div`
    background:#FAF9F9;
    display:contents;
    .login-card{
        background:#F5F5F5;
        border:1px solid #036822;
        min-height:350px;
        margin-top:100px;
        border-radius:5px;
        .left-side{
            background:#036822;
            flex-direction:column;
            justify-content:center;
            align-items:center;
            text-align:center;
            border-radius:0 10px 10px 0;
            img{
                width:75px;
            }
        }
    }

    .right-side{
        .ant-form{
            width:70%;
            .ant-form-item{
                margin-bottom:30px !important;
            }

            .ant-input{
                background:transparent;
            }
            .ant-input-affix-wrapper{
                // border: none;
                // border-bottom: 1px solid #F5F5F5;
                border: none;
                border-bottom: 1px solid #c2c2c2;
                border-radius: 0;
                background: transparent;
                &:focus{
                    box-shadow:none;
                }
            }
            .ant-input-prefix{
               span{
                   font-size:20px;
                   color:#036822;
               }
            }
            .ant-btn{
                width:100%;
                background:#036822;
                margin-top:10px;
                border:none;
                border-radius:5px;
                height:40px;
            }
        }

    }
`;

export default LoginWrapper;