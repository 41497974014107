import axios from 'axios';
import config from '../config';
import { updateLoading, logout } from '../redux/actions';
import { message } from 'antd';
import reduxStore from '../redux/store';


const serviceInstance = axios.create({
  baseURL: config.serviceApiUrl,
  // timeout: 1000
});

const authInstance = axios.create({
  baseURL: config.authApiUrl,
  // timeout: 1000
});



const requestInterceptor = (request) => {
  reduxStore.dispatch(updateLoading(true));
  if (['/login'].indexOf(request.url) !== -1) {
    return request;
  }
  request.headers['Authorization'] = `Bearer ${reduxStore.getState().userInfo.authToken}`;
  return request;
};

const responseInterceptor = (response) => {
  reduxStore.dispatch(updateLoading(false));
  return response;
};

const errorCatchHandler = (error) => {
  reduxStore.dispatch(updateLoading(false));
  if (error.response) {
    if (error.response.data?.message === "Token is not active") {
      reduxStore.dispatch(logout())
    } else {
      message.error(error.response.data.message);
    }
  }
  return Promise.reject(error);
}

serviceInstance.interceptors.request.use(requestInterceptor, errorCatchHandler);
serviceInstance.interceptors.response.use(responseInterceptor, errorCatchHandler);

authInstance.interceptors.request.use(requestInterceptor, errorCatchHandler);
authInstance.interceptors.response.use(responseInterceptor, errorCatchHandler);

export {
  authInstance, serviceInstance
};