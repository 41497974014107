import React, { useEffect } from "react";
import LayoutWrapper from "./Layout.style";
import { Layout } from "antd";
import AppLayout from "./appLayout/AppLayout";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import Login from "../pages/Login";
import AdminsList from "../pages/admins/adminsList";
import ServicesList from "../pages/services/servicesList";
import EntitiesList from "../pages/entiries/entitiesList";
import ViewService from "../pages/services/viewService";
import AddAdmin from "../pages/admins/addAdmins";
import AddEntity from "../pages/entiries/addEntity";
import { useSelector } from "react-redux";
import AddService from "../pages/services/addService";

import { ProgressBar } from "primereact/progressbar";

const PrivateRoute = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};
const ApplicationLayout = () => {
  const isLoading = useSelector((state) => state.isLoading);
  const role = useSelector((state) => state.userInfo?.role);
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [isLoading]);
  return (
    // <Spin spinning={isLoading}>
    <LayoutWrapper>
      {isLoading && (
        <>
          <div className="loader-overlay">
            <ProgressBar
              mode="indeterminate"
              color="#036822"
              style={{ height: "5px", marginTop: "0" }}
            ></ProgressBar>{" "}
          </div>
        </>
      )}
      <Layout>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" exact element={<PrivateRoute />}>
              <Route path="/" element={<AppLayout />}>
                {
                  (parseInt(role) === 1) ?
                    (<>
                      <Route path="/admins" element={<AdminsList />} />
                      <Route path="/admins/add-admin" element={<AddAdmin />} />
                      <Route path="/admins/update-admin" element={<AddAdmin />} />

                      <Route path="/entities" element={<EntitiesList />} />
                      <Route path="/entities/add-entity" element={<AddEntity />} />
                      <Route path="/entities/update-entity" element={<AddEntity />} />
                      <Route path="*" element={<Navigate to={"/entities"} />} />
                    </>)
                    :
                    (<>
                      <Route path="/services" element={<ServicesList />} />
                      <Route path="/services/add-service" element={<AddService />} />
                      <Route path="/services/view-service" element={<ViewService />} />
                      <Route path="/services/update-service" element={<AddService />} />
                      <Route path="*" element={<Navigate to={"/services"} />} />
                    </>)
                }
              </Route>
              {/* <Route path="*" element={<Navigate to={"/"} />} /> */}
            </Route>
          </Routes>
        </Router>
      </Layout>
    </LayoutWrapper>
    // </Spin>
  );
};

export default ApplicationLayout;
