import styled from 'styled-components';

const AdminWrapper = styled.div`
    .ant-btn-primary.edit-button {
        border-color: #018B2B;
        background: #018B2B;
    }

`;

export default AdminWrapper;