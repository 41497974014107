import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { authentication } from "../../services";

import ApplicationHeader from "../../layout/header/Header";
import LoginWrapper from "./style";
import qrCode from "../../assets/images/qr-code-scan.svg";
import { setAuthInformation } from "../../redux/actions";
import utils from "../../helpers/utils";
import config from "../../config";

const Login = (_props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      email: "",
      password: "",
    });
  }, [form]);

  const onFinish = (values) => {
    form.setFieldsValue({
      email: values?.email?.toLowerCase()?.trim(),
      password:  values.password?.trim()
    })
    authentication
      .login({ email: values?.email?.toLowerCase()?.trim(), password: values.password?.trim() })
      .then((response) => {
        dispatch(
          setAuthInformation({
            id: response.data.id,
            token: response.data.tooken,
            role: response.data.role,
            name: response.data.name,
          })
        );
        sessionStorage.setItem("accessToken", response.data.tooken)
        sessionStorage.setItem("role", response.data.role)
        sessionStorage.setItem("name", response.data.name)
        if (utils.isAdmin(response.data.role)) {
          navigate("/entities", { replace: true });
        } else {
          navigate("/services", { replace: true });
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <React.Fragment>
      <ApplicationHeader auth={false} />

      <LoginWrapper className="row">
        <div className="login-card mx-auto col-8 row p-0">
          <div className="left-side col-md-6 d-none d-md-flex">
            <img src={qrCode} alt="qr" />
            <h2 className="font-fm-bold text-white">
              QR Code Management System
            </h2>
          </div>
          <div className="right-side col-md-6 col-xs-12 d-flex flex-column justify-content-center align-items-center">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email",
                  },
                  {
                    pattern: config.regex.email,
                    message: "please insert valid email",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password ",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </LoginWrapper>
    </React.Fragment>
  );
};

export default Login;
