import styled from 'styled-components';

const BreadcrumbWrapper = styled.div`
.page-title{
    font-size:26px;
    color:white;
    margin-top: 26px;
    margin-bottom: 20px;
}
.breadcrumb-details{
    color:white;
    display:flex;
    align-items: center;

    .arrow-next{
        font-size:12px;
        margin: 0 5px;
        
    }
    .breadcrumb-item{
        font-size:20px;
        color:white;
        text-decoration:none;
        margin:0;
    }
}
`;
export default BreadcrumbWrapper;