import React,{useState} from "react";
import { Drawer, Menu } from "antd";
import NavbarWrapper from "./Navbar.style";
import qrCode from "../../assets/images/qr-code-scan.svg";
import { useSelector } from "react-redux";
import utils from "../../helpers/utils";


const ApplicationNavbar = (props) => {
  const userRole = useSelector((state) => state.userInfo.role);
  const isAdmin = utils.isAdmin(userRole);
  const [selectedKeys,setSelectedKeys] = useState(isAdmin ? ['1']: ['3']);
  return (
    <Drawer
      placement="left"
      closable={false}
      onClick={() => props.toggle(false)}
      onClose={() => props.toggle(false)}
      visible={props.collapsed}
      className="drawer-custom"
      bodyStyle={{padding:0}}
      drawerStyle={{ background: "#036822" }}
      width={250}
    >
      <NavbarWrapper>
        <div className="logo">
          <img src={qrCode} width="35" alt="qr code" />
          <p className="m-0 font-fm-bold">QR Code Management System</p>
        </div>

        <Menu
          className="menu-sidebar"
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={["1"]}
          selectedKeys={selectedKeys}
          onSelect={(ele) => setSelectedKeys(ele.selectedKeys)}
          items={utils.getItemsSidebar(isAdmin)}
        />
      </NavbarWrapper>
    </Drawer>
  );
};

export default ApplicationNavbar;
