import styled from 'styled-components';
const LayoutWrapper = styled.div`
    .ant-layout{
        min-height:100vh;
    }
    .ant-layout-content{
        background:#fff;
    }
`;

export default LayoutWrapper;