import { serviceInstance } from "./instance";
// import mock from './mock.json';


export const getAllEntites = async () => {
    return serviceInstance.get('/entities/');
    // return mock.entites.getAll.success;
}
export const getEntityById = async (id) => {
    return serviceInstance.get(`/entities/${id}`);
    // return mock.entites.getById.success;

}
export const addNewEntity = async (data) => {
    const submitData = { ...data, code: data.code?.trim(), namear: data.namear?.trim(), nameen: data.nameen?.trim() }
    return serviceInstance.post(`/entities/`, submitData);
    // return mock.entites.add.success;


}
export const updateEntity = async (id, data) => {
    return serviceInstance.put(`/entities/${id}`, data);
    // return mock.entites.update.success

}
export const deleteEntity = (id) => {
    return serviceInstance.delete(`/entities/${id}`);
    // return mock.entites.delete.succss

}