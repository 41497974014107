import React, { useEffect, useState } from "react";
import AddEntityWrapper from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, message, Button } from "antd";
import { entites } from "../../../services";
import { useRef } from "react";

const AddEntity = (_props) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);
  const id = location.state?.id;
  const navigate = useNavigate();

  const onFinish = () => {
    const data = form.getFieldsValue();
    data["services"] = services;
    data["users"] = users;
    if (id) {
      entites.updateEntity(id, data).then(() => {
        message.success("Submit success!");
        navigate("/entities", { replace: true });
      });
    } else {
      entites.addNewEntity(data).then(() => {
        message.success("Submit success!");
        navigate("/entities", { replace: true });
      });
    }
  };

  const handleCancel = () => {
    navigate("/entities", { replace: true });
  }

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };

  const shouldLog = useRef(true);
  useEffect(() => {
    if (id && shouldLog.current) {
      shouldLog.current = false;
      entites.getEntityById(id).then((response) => {
        const item = response.data;
        setUsers(response.data.users);
        form.setFieldsValue({
          id: item.id,
          nameen: item.nameen,
          namear: item.namear,
          code: item.code,
        });
        if (item.services && item.services.length) {
          setServices(item.services);
        }
      });
    }
  }, [id, form]);

  return (
    <AddEntityWrapper>
      <Form
        form={form}
        className="row"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          validateTrigger='onBlur'
          className="col-md-3 col-xs-12"
          name="code"
          label="Code"
          rules={[{ required: true, message: "this field is required" }, { pattern: /(.|\s)*\S(.|\s)*/, message: "this field is required" },]}
        >
          <Input disabled={id} placeholder="Code" />
        </Form.Item>
        <Form.Item
          validateTrigger='onBlur'
          className="col-md-3 col-xs-12"
          name="nameen"
          label="Name (English)"
          rules={[{ required: true, message: "this field is required" }, { pattern: /(.|\s)*\S(.|\s)*/, message: "this field is required" },]}
        >
          <Input placeholder="Name (English)" />
        </Form.Item>

        <Form.Item
          validateTrigger='onBlur'
          className="col-md-3 col-xs-12"
          name="namear"
          label="Name (Arabic)"
          rules={[{ required: true, message: "this field is required" }, { pattern: /(.|\s)*\S(.|\s)*/, message: "this field is required" },]}
        >
          <Input placeholder="Name (Arabic)" />
        </Form.Item>

        {/* <ServiceList
          services={services}
          onUpdateService={(data) => {
            setServices(data);
          }}
        /> */}

        <Form.Item className="mt-4 text-center">
          <Button
            onClick={handleCancel}
            type="primary"
            className="mx-4 button cancel bg-transparent"
          >
            Cancel
          </Button>
          <Button className="add-entity" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </AddEntityWrapper>
  );
};

export default AddEntity;
