import { serviceInstance } from "./instance";
// import mock from './mock.json';



export const getAllServices = async () => {
    return serviceInstance.get('/services/');
    // return mock.services.getAll.success;
}
export const getServiceById = async (id) => {
    return serviceInstance.get(`/services/${id}`);
    // return mock.services.getById.success;

}

export const getServiceKeys = async (url, headersValues, body = {}) => {
    const postBody = JSON.stringify(body)
    return serviceInstance.post(`/services/keys/`, { url: url.trim(), postBody }, { headers: headersValues });
    // return mock.services.getKeys.success;
}
export const addNewService = async (data, headersValues) => {
    return serviceInstance.post(`/services/`, data, { headers: headersValues });
    // return mock.services.add.success;
}
export const updateService = async (id, data, headersValues) => {
    return serviceInstance.put(`/services/${id}`, data, { headers: headersValues });
    // return mock.services.update.success;

}
export const deleteService = async (id) => {
    return serviceInstance.delete(`/services/${id}`);
    // return mock.services.delete.succss;

}