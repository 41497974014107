import React, { useState, useEffect } from "react";
import ServicesWrapper from "./style";
import { Table, Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { getAllServices, deleteService } from "../../../services/services";
import { useRef } from "react";

const ServicesList = (_props) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [servicesList, setServicesList] = useState([]);
  const [allServicesList, setAllServicesList] = useState([]);
  const navigate = useNavigate();

  const addService = () => {
    navigate("add-service", { replace: true });
  };

  const columns = [
    {
      title: "Arabic Name",
      dataIndex: "namear",
      key: "namear",
      width: "16%"
    },
    {
      title: "English Name",
      dataIndex: "nameen",
      key: "nameen",
      width: "16%"
    },
    {
      title: "No. keys",
      dataIndex: "numberOfServices",
      key: "numberOfServices",
      width: "16%"
    },
    {
      title: "service URL",
      dataIndex: "serviceurl",
      key: "serviceurl",
      width: "16%"
    },
    {
      title: "QR URL",
      dataIndex: "QRURL",
      key: "QRURL",
      width: "16%"
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <div className="d-flex">
            <Button
              onClick={() => viewService(record)}
              className="mx-2"
              type="primary"
              icon={<EyeOutlined />}
            />
            <Button
              className="mx-2"
              type="primary"
              danger
              onClick={() => handleDelete(record)}
              icon={<DeleteOutlined />}
            />
          </div>
        );
      },
    },
  ];

  const shouldLog= useRef(true);

  useEffect(() => {
    if(shouldLog.current){
      shouldLog.current = false;
      getAllServices().then((response) => {
        const services = response.data.map((ele) => {
          return {
            ...ele,
            numberOfServices:ele.keys.length
          }
        });
  
        setServicesList(services);
        setAllServicesList(services);
      });
    }

  }, []);

  const toLowerCase = (str) => {
    return str.toLowerCase();
  };

  const submitFilter = () => {
    if (!searchFilter) {
      setServicesList(allServicesList);
      return;
    }
    const filteredServices = allServicesList.filter((ele) => {
      return (
        toLowerCase(ele.namear).indexOf(toLowerCase(searchFilter)) !== -1 ||
        toLowerCase(ele.nameen).indexOf(toLowerCase(searchFilter)) !== -1 ||
        // toLowerCase(ele.serviceurl).indexOf(toLowerCase(searchFilter)) !== -1 ||
        toLowerCase("" + ele.numberOfServices).indexOf(
          toLowerCase(searchFilter)
        ) !== -1
      );
    });
    setServicesList(filteredServices);
  };

  const resetFilter = () => {
    setSearchFilter("");
    setServicesList(allServicesList);
  };

  const handleDelete = (record) => {
    deleteService(record.id).then(() => {
      removeService(record.id);
      message.success(`${record.nameen} service was deleted successfully`);
    });
  };

  const removeService = (id) => {
    const services = [...allServicesList];
    const index = services.findIndex((obj) => {
      return obj.id === id;
    });
    services.splice(index, 1);
    setAllServicesList(services);
    setServicesList(services);
  };

  const viewService = (record) => {
    navigate("view-service", { replace: true, state: record });
  };

  return (
    <ServicesWrapper>
      <div className="my-2 d-flex justify-content-end">
        <Button onClick={addService} type="primary">
          Add New Service
        </Button>
      </div>
      <div className="my-2 d-flex filter">
        <div className="my-2 d-flex filter row">
          <div className="col-9">
            <Input
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
              placeholder="search ..."
            />
          </div>
          <div className="col-2 d-flex justify-content-between">
            <Button
              onClick={submitFilter}
              disabled={!searchFilter}
              type="primary"
            >
              Search
            </Button>
            <Button
              onClick={resetFilter}
              disabled={!searchFilter}
              className="mx-2"
              type="primary"
              danger
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      <Table columns={columns} rowKey={"id"} dataSource={servicesList} />
    </ServicesWrapper>
  );
};

export default ServicesList;
