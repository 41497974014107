import styled from 'styled-components';

const AddEntityWrapper = styled.div`
    .add-entity{
        background: #036822;
        width: 150px;
        border: none;
        height: 42px;
        border-radius: 5px;
    }
    .button{
        background: #036822;
        width: 150px;
        border: none;
        height: 42px;
        border-radius: 5px;
    }
    .cancel {
        color: #036822;
        border: solid 1px #036822;
    }

`;


export default AddEntityWrapper;