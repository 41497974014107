import ConfigurationIcon from '../assets/images/configuration.svg';
import ElectronicInvoiceIcon from '../assets/images/electronic-invoice.svg';
import UserIcon from '../assets/images/user.svg';
import Info from '../assets/images/info-icon.svg';
import { Link } from 'react-router-dom';
import AdminPdf from '../assets/pdf/QR_Code_Guideline_Goverment_Entity_User.pdf'
import SuperAdminPdf from '../assets/pdf/QR_code_Guideline_Super_Admin_User.pdf'

const items = [
    {
        key: "1",
        label: <Link className='text-decoration-none font-fm-semi-bold' to="entities">Entities Management</Link>,
        icon: <img alt='entities' src={ConfigurationIcon} />,
        admin: `true`

    },
    {
        key: "2",
        label: <Link className='text-decoration-none font-fm-semi-bold' to="admins">User Management</Link>,
        icon: <img alt='admins' src={UserIcon} />,
        admin: `true`
    },
    {
        key: "3",
        label: <Link className='text-decoration-none font-fm-semi-bold' to="services">Services Management</Link>,
        icon: <img alt='services' src={ElectronicInvoiceIcon} />,
        admin: `false`

    },
    {
        key: "4",
        label: <Link target="_blank" className='text-decoration-none download-link font-fm-semi-bold' to={AdminPdf}>Guideline</Link>,
        icon: <img alt='info' src={Info} />,
        admin: `false`,
        download: true
    },
    {
        key: "5",
        label: <Link target="_blank" className='text-decoration-none download-link font-fm-semi-bold' to={SuperAdminPdf}>Guideline</Link>,
        icon: <img alt='info' src={Info} />,
        admin: `true`,
        download: true
    },
];


export default items;