import { authInstance } from "./instance";
import mock from './mock.json';


export const getAllAdmins = async () => {
    return authInstance.get('/');
    // return mock.admins.getAll.success;
}
export const getAdminById = async (id) => {
    return authInstance.get(`/${id}`);
    // return mock.admins.getById.success;

}
export const addNewAdmin = async (data) => {
    const submitData = { ...data, firstname: data.firstname?.trim(), lastname: data.lastname?.trim() }
    return authInstance.post(`/`, submitData);
    // return mock.admins.add.success;
}
export const updateAdmin = async (id, data) => {
    return authInstance.patch(`/${id}`, data);
    // return mock.admins.update.success;

}
export const deleteAdmin = async (id) => {
    return authInstance.delete(`/${id}`);
    // return mock.admins.delete.success;

}