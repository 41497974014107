import items from "../layout/items";
const utils  = {
    isAdmin : (role) =>{
        return role === "1";
    },
    getRoleDescription : (role) =>{
        return role === "1" ? "Super Admin " : "Admin";
    },
    getItemsSidebar: (isAdmin) =>{
        return isAdmin ? items.filter((ele) => ele.admin ===`true`) : items.filter((ele) => ele.admin !==`true`);
    },
    hasAccessToken: () => {
        return sessionStorage.getItem('accessToken')
    }
};

export default utils;