import styled from 'styled-components';
const SidebarWrapper = styled.div`
    .ant-layout-sider{
        height:100%;
        background:#036822;
    }
    .logo {
        height: 70px;
        background: #018B2B;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 18px;
    }
    .ant-menu-item{
        font-size:17px;
    }
    .ant-menu.ant-menu-dark .ant-menu-item-selected{
        background:transparent;
        border-left: 4px solid #E2E6FF;
    }
    .menu-sidebar{
        background:#036822;
    }
`;

export default SidebarWrapper;